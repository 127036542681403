export default [
            {
                id: "abjua-nigeria",
                city: "Abjua",
                country: "Nigeria",
                companyName: "Wood Mackenzie (Nigeria) Limited",
                address: "4th Floor, Building 2 Rivers House<br>83, Ralph Shodeinde Street<br>Central Business District,<br>Abuja",
                tel: "+234 9 2780027-9",
                email: "contactus@woodmac.com",
                location: {
                    lat: 9.0534074,
                    lng: 7.4973724
                }
            },
            {
                id: "johannesburg-south-africa",
                city: "Johannesburg",
                country: "South Africa",
                companyName: "Wood Mackenzie Ltd",
                address: "Office 71, 2nd Floor - West Tower<br>Nelson Mandela Square<br>Maude Street<br>P.O.Box 785553<br>Johannesburg<br>2146",
                tel: "+27 11 881 5995",
                fax: "+27 11 881 5611",
                email: "contactus@woodmac.com",
                location: {
                    lat: -26.1060534,
                    lng: 28.0520245
                }
            },
            {
                id: "beijing-china",
                city: "Beijing",
                country: "China",
                companyName: "Wood Mackenzie Ltd",
                address: "2619 China World Office 1<br> No.1 Jianguomenwai Avenue<br> Beijing<br>100004",
                tel: "+86 10 59652600",
                fax: "+86 10 59652601",
                email: "contactus@woodmac.com",
                location: {
                    lat: 39.911061,
                    lng: 116.456151
                }
            },
            {
                id: "brisbane-australia",
                city: "Brisbane",
                country: "Australia",
                companyName: "Wood Mackenzie",
                address: "Riverside Centre<br>Suite 1849<br>Level 18, 123 Eagle Street<br>Brisbane<br>Qld 4000",
                tel: "+61 2 8224 8888",
                fax: "+61 2 8224 8899",
                email: "contactus@woodmac.com",
                location: {
                    lat: -27.4671408,
                    lng: 153.0282271
                }
            },
            {
                id: "delhi-india",
                city: "Delhi",
                country: "India",
                companyName: "Wood Mackenzie Ltd",
                address: "406, Level 4, Augusta Point<br>Sector 53, Golf Course Road<br>Gurgaon<br>122002",
                tel: "+91 12 4435 4043",
                fax: "+91 12 4435 4001",
                email: "contactus@woodmac.com",
                location: {
                    lat: 28.4583036,
                    lng: 77.0947131
                }
            },
            {
                id: "hong-kong-china",
                city: "Hong Kong",
                country: "China",
                companyName: "Wood Mackenzie",
                address: "Level 9, Central Building<br>1-3 Pedder Street<br>Central<br>Hong Kong",
                tel: "+852 3975 2618",
                fax: "+852 3975 2800",
                email: "contactus@woodmac.com",
                location: {
                    lat: 22.2811899,
                    lng: 114.1550133
                }
            },
            {
                id: "jakarta-indonesia",
                city: "Jakarta",
                country: "Indonesia",
                companyName: "Wood Mackenzie",
                address: "Menara BCA 45th Floor<br>Jl. MH. Thamrin No. 1<br>Jakarta<br>10310",
                tel: "+62 21 2358 5711",
                fax: "+62 21 2358 4401",
                email: "contactus@woodmac.com",
                location: {
                    lat: -6.1962546,
                    lng: 106.8200345
                }
            },
            {
                id: "kuala-lumpur-malaysia",
                city: "Kuala Lumpur",
                country: "Malaysia",
                companyName: "Wood Mackenzie",
                address: "Menara BCA 45th Floor<br>Jl. MH. Thamrin No. 1<br>Jakarta<br>10310",
                tel: "+603 2615 0055",
                fax: "+603 2615 0088",
                email: "contactus@woodmac.com",
                location: {
                    lat: 3.1582371,
                    lng: 101.7107345
                }
            },
            {
                id: "perth-australia",
                city: "Perth",
                country: "Australia",
                companyName: "Wood Mackenzie",
                address: "Level 5<br>5 Mill Street<br>Perth<br>WA 6000",
                tel: "+61 8 6216 7538",
                fax: "+61 8 6216 7501",
                email: "contactus@woodmac.com",
                location: {
                    lat: -31.9543125,
                    lng: 115.850174
                }
            },
            {
                id: "seoul-korea",
                city: "Seoul",
                country: "Korea",
                companyName: "Wood Mackenzie",
                address: "21F Seoul Finance Center<br>136 Sejongdae-ro, Jung-gu<br>Seoul<br>04520",
                tel: "+82 2 3782 4810",
                fax: "+82 2 3782 4555",
                email: "contactus@woodmac.com",
                location: {
                    lat: 37.5683738,
                    lng: 126.9756028
                }
            },
            {
                id: "singapore",
                city: "Singapore",
                country: "Singapore",
                companyName: "Wood Mackenzie",
                address: "3 Church Street<br>#29-01 Samsung Hub<br>049483",
                tel: "+65 6518 0800",
                fax: "+65 6518 0801",
                email: "contactus@woodmac.com",
                location: {
                    lat: 1.28325,
                    lng: 103.847471
                }
            },
            {
                id: "sydney-australia",
                city: "Sydney",
                country: "Australia",
                companyName: "Wood Mackenzie",
                address: "Level 13<br>50 Pitt StreetSydney<br>NSW 2000",
                tel: "+61 2 8224 8888",
                fax: "+61 2 8224 8899",
                email: "contactus@woodmac.com",
                location: {
                    lat: -33.8913726,
                    lng: 151.2016694
                }
            },
            {
                id: "tokyo-japan",
                city: "Tokyo",
                country: "Japan",
                companyName: "Wood Mackenzie",
                address: "7th Floor Tekko Building<br>1-8-2 Marunouchi, Chiyoda-ku<br>Tokyo<br>100-0005",
                tel: "+81 3 6551 2888",
                fax: "+81 3 5224 6011",
                email: "contactus@woodmac.com",
                location: {
                    lat: 35.6826743,
                    lng: 139.7678463
                }
            },
            {
                id: "shanghai-china",
                city: "Shanghai",
                country: "China",
                companyName: "Wood Mackenzie",
                address: "7/F, One ICC, Shanghai ICC<br>No.999, Middle Huaihai Road<br>Xuhui District<br>Shanghai",
                email: "contactus@woodmac.com",
                location: {
                    lat: 31.1816417,
                    lng: 121.4444039
                }
            },
            {
                id: "malaysia",
                city: "Malaysia",
                country: "Malaysia",
                companyName: "PCI Wood Mackenzie (Xylenes and Polyesters)",
                address: "Suite 1208, Menara PJ<br>AMCORP Trade Centre<br>No 18 Jalan Persiaran Barat<br>46050 Petaling Jaya<br>Selangor Darul Ehsan",
                tel: "+60 3 7954 82 02",
                email: "chemicals@woodmac.com",
                location: {
                    lat: 3.1049145,
                    lng: 101.6448602
                }
            },
            {
                id: "shanghai-china-pci",
                city: "Shanghai",
                country: "China",
                companyName: "PCI Wood Mackenzie – Shanghai, ICC",
                address: "Room 722, 7/F, One ICC<br>Shanghai ICC, No.999<br>Middle Huaihai Road<br>Xuhui District<br>Shanghai 200031",
                tel: "+86 21 6037 7335",
                email: "chemicals@woodmac.com",
                location: {
                    lat: 31.2158826,
                    lng: 121.4560893
                }
            },
            {
                id: "edinburgh-uk",
                city: "Edinburgh",
                country: "UK",
                companyName: "Wood Mackenzie Ltd",
                address: "Exchange Place 2<br> 5 Semple Street<br> Edinburgh<br> EH3 8BL",
                tel: "+44 131 243 4400",
                fax: "+44 131 243 4495",
                email: "contactus@woodmac.com",
                location: {
                    lat: 55.9447937,
                    lng: -3.2067389
                }
            },
            {
                id: "guildford-uk",
                city: "Guildford",
                country: "UK",
                companyName: "Wood Mackenzie Ltd",
                address: "2nd Floor<br>65 Woodbridge Road<br>Guildford<br>GU1 4RD",
                tel: "+44 1483 596 600",
                fax: "+44 1483 596 601",
                email: "contactus@woodmac.com",
                location: {
                    lat: 51.240833,
                    lng: -0.577925
                }
            },
            {
                id: "london-uk",
                city: "London",
                country: "UK",
                companyName: "Wood Mackenzie Ltd",
                address: "5th Floor<br>1 Finsbury Square<br>London<br>EC2A 1AE",
                tel: "+44 20 3060 0400",
                fax: "+44 20 3060 0500",
                email: "contactus@woodmac.com",
                location: {
                    lat: 51.5207974,
                    lng: -0.0899236
                }
            },
            {
                id: "vienna-austria",
                city: "Vienna",
                country: "Austria",
                companyName: "Wood Mackenzie Ltd",
                address: "Niederlassung Österreich<br>Rembrandtstrasse 36/14<br>Vienna<br>1020",
                tel: "+43 664/9136277",
                fax: "+43 12761161",
                email: "contactus@woodmac.com",
                location: {
                    lat: 48.2231673,
                    lng: 16.3714939
                }
            },
            {
                id: "aberdeen-uk",
                city: "Aberdeen",
                country: "UK",
                companyName: "Wood Mackenzie Ltd",
                address: "Unit G1, Enterprise Centre<br>Aberdeen Energy Park<br>Exploration Drive<br>Bridge of Don<br>Aberdeen<br>AB23 8GX",
                tel: "+44 1224 258 150",
                email: "contactus@woodmac.com",
                location: {
                    lat: 57.1891544,
                    lng: -2.0896921
                }
            },
            {
                id: "guildford-uk-pci",
                city: "Guildford",
                country: "UK",
                companyName: "PCI Wood Mackenzie (Xylenes and Polyesters)",
                address: "79 Portsmouth Road<br>Guildford<br>Surrey<br>GU2 4BX",
                tel: "+44 1483 302 267",
                email: "chemicals@woodmac.com",
                location: {
                    lat: 51.2322197,
                    lng: -0.5813232
                }
            },
            {
                id: "guilsborough-uk",
                city: "Guilsborough",
                country: "UK",
                companyName: "PCI Wood Mackenzie (Xylenes and Polyesters)",
                address: "Seatons Business Centre<br>High Street<br>Guilsborough<br>Northamptonshire<br>NN6 8PU",
                tel: "+44 1604 749 001",
                email: "chemicals@woodmac.com",
                location: {
                    lat: 52.3515012,
                    lng: -1.0129975
                }
            },
            {
                id: "speldhurst-uk",
                city: "Speldhurst",
                country: "UK",
                companyName: "PCI Wood Mackenzie (PET Packaging and RPET)",
                address: "Unit C1<br>Speldhurst Business Park<br>Langton Road<br>Speldhurst<br>Kent<br>TN3 0AQ",
                tel: "+44 1892 864 382",
                email: "chemicals@woodmac.com",
                location: {
                    lat: 51.145373,
                    lng: 0.2164705
                }
            },
            {
                id: "holzweg-germany",
                city: "Holzweg",
                country: "Germany",
                companyName: "PCI Wood Mackenzie (Nylon and Fibres)",
                address: "PCI Research Gmbh<br>Holzweg 14<br>61440 Oberursel",
                tel: "+49 6171 989 090",
                email: "chemicals@woodmac.com",
                location: {
                    lat: 50.2026664,
                    lng: 8.5783573
                }
            },
            {
                id: "buenos-aires-argentina",
                city: "Buenos Aires",
                country: "Argentina",
                companyName: "Wood Mackenzie",
                address: "Ing. Enrique Butty 240<br>Edificio Laminar Plaza, 5th floor<br>Buenos Aires<br>C1001AF",
                tel: "+54 11 5192 5545",
                email: "contactus@woodmac.com",
                location: {
                    lat: -34.596714,
                    lng: -58.3730471
                }
            },
            {
                id: "lima-peru",
                city: "Lima",
                country: "Peru",
                companyName: "Wood Mackenzie Peru SRL",
                address: "Jiron Huascar 2055<br>Jesus Maria<br>Lima<br>11",
                email: "contactus@woodmac.com",
                location: {
                    lat: -12.0830245,
                    lng: -77.0526633
                }
            },
            {
                id: "rio-de-janeiro-brazil",
                city: "Rio de Janeiro",
                country: "Brazil",
                companyName: "Wood Mackenzie Peru SRL",
                address: "Avenida Nilo Peçanha 50/ 3018<br>Rio de Janeiro<br>CEP 20020-100",
                tel: "+55 21 3550 7700",
                email: "contactus@woodmac.com",
                location: {
                    lat: -22.7867432,
                    lng: -43.3136015
                }
            },
            {
                id: "mexico-city-mexico",
                city: "Mexico City",
                country: "Mexico",
                companyName: "Wood Mackenzie",
                address: "Andres Bello 10, 7th floor, Suite 712<brPolanco Chapultepec<br>11560<br>Mexico City",
                tel: "+52 55 360 0670",
                email: "contactus@woodmac.com",
                location: {
                    lat: 19.4269274,
                    lng: -99.1980438
                }
            },
            {
                id: "dubai-uae",
                city: "Dubai",
                country: "UAE",
                companyName: "Wood Mackenzie",
                address: "Level 27, Office 27A<br>Emirates Towers<br>Sheikh Zayed Road<br>Dubai<br>P.O. Box 66354",
                tel: "+971 4 376 53 00",
                fax: "+971 4 376 53 01",
                email: "contactus@woodmac.com",
                location: {
                    lat: 25.2176077,
                    lng: 55.2806745
                }
            },
            {
                id: "annapolis-usa",
                city: "Annapolis",
                country: "USA",
                companyName: "Wood Mackenzie",
                address: "One Park Place<br> Suite 575<br> Annapolis<br>Maryland 21401",
                tel: "+1 410 295 4200",
                fax: "+1 410 295 4301",
                email: "contactus@woodmac.com",
                location: {
                    lat: 38.9648619,
                    lng: -76.5258462
                }
            },
            {
                id: "boston-usa",
                city: "Boston",
                country: "USA",
                companyName: "Wood Mackenzie",
                address: "420 Bedford Street<br>Lexington<br>Massachusetts 02420",
                tel: "+1 781 869 6800",
                email: "contactus@woodmac.com",
                location: {
                    lat: 42.4721971,
                    lng: -71.2504194
                }
            },
            {
                id: "calgary-canada",
                city: "Calgary",
                country: "Canada",
                companyName: "Wood Mackenzie Canada Ltd",
                address: "Suite 810, 335 - 8th Avenue SW<br>Calgary, AB,<br>T2P 1C9",
                tel: "+1 587 390 4300",
                fax: "+1 587 390 4350",
                email: "contactus@woodmac.com",
                location: {
                    lat: 51.0455404,
                    lng: -114.0722717
                }
            },
            {
                id: "houston-usa",
                city: "Houston",
                country: "USA",
                companyName: "Wood Mackenzie Canada Ltd",
                address: "5847 San Felipe<br>10th Floor, Suite 1000<br>Houston<br>Texas 77057",
                tel: "+1 713 470 1600",
                fax: "+1 713 470 1701",
                email: "contactus@woodmac.com",
                location: {
                    lat: 29.7494912,
                    lng: -95.4841513
                }
            },
            {
                id: "new-york-usa",
                city: "New York",
                country: "USA",
                companyName: "Wood Mackenzie",
                address: "452 Fifth Avenue<br>21st Floor<br>New York<br>NY 10018",
                tel: "+1 212 915 2300",
                fax: "+1 212 915 2402",
                email: "contactus@woodmac.com",
                location: {
                    lat: 40.7521163,
                    lng: -73.9844951
                }
            },
            {
                id: "toronto-canada",
                city: "Toronto",
                country: "Canada",
                companyName: "Wood Mackenzie",
                address: "100 King Street West<br>Suite 5700<br>Toronto, ON<br>M5X 1C7",
                tel: "+1 (416) 915 3105",
                email: "contactus@woodmac.com",
                location: {
                    lat: 43.6486823,
                    lng: -79.3840918
                }
            },
            {
                id: "astana-kazakhstan",
                city: "Astana",
                country: "Republic of Kazakhstan",
                companyName: "Wood Mackenzie",
                address: "Business Center, Vodno-Zelenyi<br> Boulevard<br> VP-56, Floor 4 12/1<br> Dinmukhamed Kunayev Street</br>Esil District, Astana",
                tel: "+7 7172 575470",
                fax: "+7 7172 575470",
                email: "",
                location: {
                    lat: 51.1313191,
                    lng: 71.4211881
                }
            },
            {
                id: "moscow-russia",
                city: "Moscow",
                country: "Russia",
                companyName: "Wood Mackenzie",
                address: "Lotte Plaza Business Center<br>8 Novinsky Boulevard<br>Moscow<br>121099",
                tel: "+7 495 139 0140",
                email: "",
                location: {
                    lat: 55.7512385,
                    lng: 37.5819018
                }
            }
        ];