import { 
	PATHS,
	HYSTERESIS_MENU,
	MASONRY,
	TABS,
	SLIDER,
	ALL,
	FILTER_LIST,
	PRINT,
	WALL,
	WALL_ROW,
	MODAL,
	EQUAL_HEIGHT,
	STICKY,
	FIT_TEXT,
	BANNER,
	TRIGGER_EVENTS,
	TRIGGER_KEYCODES
} from './constants';
import 'es6-promise/auto';
import './require/outliner';
import Toggler from './require/toggler';
import StickyHeader from './require/sticky-header';
import HysteresisMenu from './require/hysteresis-menu';
import ModalGallery from './require/modal-gallery';
import InputToggle from './require/input-toggle';
import Video from './require/video';
import Map from './require/map';
import Banner from 'storm-banner';
import Load from 'storm-load';

const onDOMContentLoadedTasks = [
	StickyHeader,
	Toggler,
	ModalGallery,
	InputToggle,
	Video,
	Map,
	() => {
		if(!document.querySelector(PRINT.SELECTOR)) return;

		let handler = e => {
			if(e.keycode && !~TRIGGER_KEYCODES.indexOf(e.keycode)) return;
			e.preventDefault(), window.print();
		};

		[].slice.call(document.querySelectorAll(PRINT.SELECTOR)).forEach(el => {
			TRIGGER_EVENTS.forEach(ev => { el.addEventListener(ev, handler); });
		});
	},
	() => {
		if(!document.querySelector(BANNER.SELECTOR)) return;
		Banner.init(BANNER.SELECTOR, { storageName: BANNER.STORAGE_NAME });
	},
	() => { !('ontouchstart' in window) && HysteresisMenu.init(HYSTERESIS_MENU.SELECTOR); },
	() => {
		if(!document.querySelector(MASONRY.OPTIONS.container)) return;

		Load(`${PATHS.JS_ASYNC}/macy.min.js`)
			.then(() => {
				Macy.init(MASONRY.OPTIONS);
			});
	},
	() => {
		if(!document.querySelector(TABS.SELECTOR)) return;

		Load(`${PATHS.JS_ASYNC}/storm-tabs.min.js`)
			.then(() => StormTabs.init(TABS.SELECTOR));
	},
	() => {
		if(!document.querySelector(SLIDER.SELECTOR)) return;

		Load(`${PATHS.JS_ASYNC}/flickity.min.js`)
	},
	() => {
		if(!document.querySelector(WALL.SELECTOR)) return;

		Load(`${PATHS.JS_ASYNC}/storm-wall.min.js`)
			.then(() => StormWall.init(WALL.SELECTOR));
	},
	() => {
		if(!document.querySelector(WALL_ROW.SELECTOR)) return;

		Load(`${PATHS.JS_ASYNC}/storm-wall-row.min.js`)
			.then(() => StormWallRow.init(WALL_ROW.SELECTOR));
	},
	() => {
		if(!document.querySelector(FILTER_LIST.SELECTOR)) return;

		Load(`${PATHS.JS_ASYNC}/storm-filter-list.min.js`)
			.then(() => FilterList.init(FILTER_LIST.SELECTOR));
	},
	() => {
		if(!document.querySelector(MODAL.SELECTOR)) return;

		Load(`${PATHS.JS_ASYNC}/storm-modal.standalone.min.js`)
			.then(() => StormModal.init(MODAL.SELECTOR));
	},
	() => {
		if(!document.querySelector(EQUAL_HEIGHT.SELECTOR)) return;

		Load(`${PATHS.JS_ASYNC}/storm-equal-height.standalone.min.js`)
			.then(() => {
				StormEqualHeight.init(EQUAL_HEIGHT.SELECTOR,
				    {
				        minWidth: 600
				    }
				);
			});
	},
	() => {
		if(!document.querySelector(STICKY.SELECTOR)) return;

		Load(`${PATHS.JS_ASYNC}/storm-sticky.min.js`)
			.then(() => StormSticky.init(STICKY.SELECTOR, { offset: -166 }));
	},
	() => {
		if(!document.querySelector(FIT_TEXT.SELECTOR)) return;

		Load(`${PATHS.JS_ASYNC}/storm-responsive-text.min.js`)
			.then(() => {
				StormResponsiveText.init(FIT_TEXT.SELECTOR);
			});
	}
];

//add post-load event tasks to this array
//const onLoadTasks = [];

const init = () => {
	if(!Object.assign || !('classList' in document.createElement('_'))) 
		Load(`${PATHS.JS_ASYNC}/polyfills.min.js`)
			.then(() => {
				onDOMContentLoadedTasks.forEach(fn => fn());
			});
	else onDOMContentLoadedTasks.forEach(fn => fn());
};

//attach anything you wish to expose on a window level here
//global.UI = {};

if('addEventListener' in window)
	onDOMContentLoadedTasks.length && window.addEventListener('DOMContentLoaded', init);
	//onLoadTasks.length && window.addEventListener('load', () => { onLoadTasks.forEach((fn) => fn()); });