export const PATHS = {
	JS_ASYNC: '/content/js/async',
	YOUTUBE_API: 'https://www.youtube.com/iframe_api'
};

export const TRIGGER_EVENTS = ['click', 'keyup'];

export const TRIGGER_KEYCODES = [13, 32];

export const TOGGLERS = {
	SELECTOR:{
		GLOBAL: '.js-toggle',
		LOCAL: '.js-toggle-local',
		GROUP: '.js-toggle__group',
		FOCUS: '.js-toggle__focus',
		HEADER_GROUP: '.js-toggle__header-group'
	}
};

export const TABS = {
	SELECTOR: '.js-tabs'
};

export const SLIDER = {
	SELECTOR: '.main-carousel'
};

export const PRINT = {
	SELECTOR: '.js-print'
};

export const WALL = {
	SELECTOR: '.js-wall'
};

export const EQUAL_HEIGHT = {
	SELECTOR: '.js-equal-height'
};

export const MODAL = {
	SELECTOR: '.js-modal'
};

export const STICKY = {
	SELECTOR: '.js-sticky'
};

export const BANNER = {
	SELECTOR: '.js-banner',
	STORAGE_NAME: 'wmdotcom_cookie'
};

export const WALL_ROW = {
	SELECTOR: '.js-wall-row'
};

export const MASONRY = {
	OPTIONS: {
		container: '.js-masonry',
		columns: 3,
		margin: 10,
		breakAt: {
			768: 2,
			540: 1
		}
	}
};

export const HYSTERESIS_MENU = {
	SELECTOR: '.js-hysteresis-nav'
};

export const STICKY_HEADER = {
	CLASSNAME: 'js-is-stuck'
};

export const GALLERY = {
	SELECTOR: {
		LINK: '.js-gallery__trigger-link',
		SCROLLABLE_LINK: '.js-gallery__trigger-link--scrollable',
		SINGLE: '.js-gallery__trigger-single',
		DATA: '.js-gallery__trigger-data'
	}
};
export const FILTER_LIST = {
	SELECTOR: '.js-filter-list'
}

/*
export const FILTER_LIST = {
	SELECTOR: {
		LIST: '.js-filter-list',
	},
	MASONRY: {
		columns: 3,
		margin: 10,
		container: '.js-filter-list__macy',
		breakAt: {
			768: 2,
			540: 1
		}
	},
	ENDPOINT: '/content/data/latest-thinking.json',
	CONSTANTS: {
		filterSelector: '.js-filter',
		pillsContainerSelector: '.js-filter-pills',
		//masonrySelector: '.js-filter-list__macy',
		pillSelector: '.js-filter-pill',
		btnSelector: '.js-filter__load-more',
		initialSize: 12,
		batchSize: 12,
		pillEvents: ['click', 'keydown'],
		triggerKeyCodes: [13, 32]
	}
};
*/

export const INPUT_TOGGLE = {
	SELECTOR: '.js-toggle-input'
};

export const FIT_TEXT = {
	SELECTOR: '.js-fit'
};

export const VIDEO = {
	SELECTOR: {
		CONTAINER: '.js-video',
		WISTIA: '.js-video__w',
		YOUTUBE: '.js-video__yt'
	}
};

export const MAP = {
	KEY: 'AIzaSyAYPoXiOq5464sZEkqEr5_r-JgW6sQZosE',
	SELECTOR: {
		LINKS: '.js-map__trigger',
		CONTAINER: '.js-office__map',
	}
};