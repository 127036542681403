import { PATHS, MAP }  from '../../constants';
import data  from './api';
import Load from 'storm-load';

const TRIGGER_EVENTS = [window.PointerEvent ? 'pointerdown' : 'ontouchstart' in window ? 'touchstart' : 'click', 'keydown' ],
	  TRIGGER_KEYCODES = [13, 32];

const initLinks = () => {
	[].slice.call(document.querySelectorAll(MAP.SELECTOR.LINKS)).forEach((el, i) => {
		TRIGGER_EVENTS.forEach(ev => {
			el.addEventListener(ev, e => {
				if(!!e.keyCode && !~TRIGGER_KEYCODES.indexOf(e.keyCode)) return;
				el.getAttribute('data-location-id') && window.WM_MAP.openInfobox(window.WM_MAP.markers.filter(marker => marker.id === el.getAttribute('data-location-id'))[0]);
			});
		});
	});
};

export default () => {
	if(!document.querySelector(MAP.SELECTOR.CONTAINER)) return;

	Load(`${PATHS.JS_ASYNC}/storm-google-map.min.js`)
	  .then(() => { 
		  StormGoogleMap
		  	.init(MAP.SELECTOR.CONTAINER, data, { key: MAP.KEY })
			.then(map => {
				window.WM_MAP = map;
				document.querySelector(MAP.SELECTOR.LINKS) && initLinks();
			});
	  });

};