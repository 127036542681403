const defaults = {
	activeClassName: 'active'
};

//Refactor me pls
const InputToggle = {
	init(){
		this.target = this.input.parentNode;
		if(!this.target) throw new Error('Input toggle target cannot be found');

		this.boundToggle = this.toggle.bind(this);
		(this.group.length ? this.group : [this.input]).forEach(el => {
			el.addEventListener('change', this.boundToggle);
		});

		return this;
	},
	toggle(){
		//this.input.setAttribute('aria-expanded', this.input.getAttribute('aria-expanded') ? false : true);
		if(this.input.checked === true) this.target.classList.add(this.settings.activeClassName);
		else if(this.input.checked === false) this.target.classList.remove(this.settings.activeClassName);
	}
};

export default (sel, opts) => {
	let els = [].slice.call(document.querySelectorAll(sel));
	
	if(!els.length) throw new Error('Input toggle cannot be initialised, no augmentable elements found');

	[].slice.call(els).map(el => {
		return Object.assign(Object.create(InputToggle), {
			input: el,
			// group: [].slice.call(document.getElementsByName(el.getAttribute('name'))),
			group: el.getAttribute('data-toggle-group') ? [].slice.call(document.querySelectorAll(`[data-toggle-group=${el.getAttribute('data-toggle-group')}]`)) : [],
			settings: Object.assign({}, defaults, opts)
		}).init();

	});
};