import { PATHS, VIDEO } from '../../constants';
import Load from 'storm-load';

const initWistia = () => {
    window._wq = window._wq || [];
    _wq.push({ 
        id: '_all', 
        onReady(video) {
            let trigger = video.container.parentNode.parentNode.parentNode.querySelector('.js-video__trigger');
            trigger.parentNode.classList.add('is--ready');
            trigger.addEventListener('click', () => {
                trigger.parentNode.classList.add('is--started');
                video.play();
            });
        }}
    );
};

const initYouTube = () => {
    [].slice.call(document.querySelectorAll(VIDEO.SELECTOR.YOUTUBE)).forEach(el => {
        if(!el.getAttribute('data-video-id')) throw new Error(`Cannot find data-youtube-id attribute on ${el}`);
        createYTPlayer(el);
    });
};

const createYTPlayer = el => new YT.Player(el.firstElementChild, {
        height: el.getAttribute('data-video-height') || '360',
        width: el.getAttribute('data-video-width') || '640',
        videoId: el.getAttribute('data-video-id'),
        events: {
            'onReady'(e){
                el.parentNode.classList.add('is--ready');
                el.nextElementSibling.addEventListener('click', () => {
                    el.parentNode.classList.add('is--started');
                    e.target.playVideo()
                });
            }
        }
    });

export default () => {
    if(!document.querySelector(VIDEO.SELECTOR.CONTAINER)) return;

    document.querySelector(VIDEO.SELECTOR.WISTIA) && initWistia();
    if(document.querySelector(VIDEO.SELECTOR.YOUTUBE)) {
        window.onYouTubeIframeAPIReady = initYouTube;
        Load(PATHS.YOUTUBE_API).then(() => { window.setTimeout(() => { delete window.onYouTubeIframeAPIReady; }, 260); });
    }
}