import Toggler from './libs/toggler';
import { TOGGLERS } from '../../constants';

const  initToggleGroup = () => {
	let ToggleClose = document.querySelector('.js-filter__close'),
		ToggleGroup = Toggler.init(TOGGLERS.SELECTOR.GROUP, {
				targetLocal: true,
				delay: 0,
				callback(){
					if(this.isOpen) {
						ToggleClose.classList.add('active');
						ToggleGroup.forEach(toggle => {
							if(toggle !== this && toggle.isOpen) toggle.close();
						});
					}
				}
			});

		ToggleClose.addEventListener('click', () => {
			ToggleGroup.forEach(toggle => {
				toggle.close();
			});
			ToggleClose.classList.remove('active');
		});
};

const initHeaderToggleGroup = () => {
	let headerToggleGroup = Toggler.init(TOGGLERS.SELECTOR.HEADER_GROUP, {
		callback(){
			if(this.isOpen) {
				headerToggleGroup.forEach(toggle => {
					if(this.isOpen) {
						headerToggleGroup.forEach(toggle => {
							if(toggle !== this && toggle.isOpen) toggle.close();
						});
					}
				});
				if(this.targetElement.querySelector('#q')) {
					window.setTimeout(() => { this.targetElement.querySelector('#q').focus(); }, 16);
				}
			}
		}
	});
};

const toggleFns = {
	GLOBAL: () => { Toggler.init(TOGGLERS.SELECTOR.GLOBAL); },
	HEADER_GROUP: initHeaderToggleGroup,
	FOCUS: () => {  
		Toggler.init(TOGGLERS.SELECTOR.FOCUS, {
			callback(){
				if(this.isOpen) window.setTimeout(() => { document.getElementById('q').focus(); }, 16);
			}
		})
	},
	LOCAL: () => { Toggler.init(TOGGLERS.SELECTOR.LOCAL, {targetLocal: true}); },
	GROUP: initToggleGroup
};

export default () => {

	['GLOBAL', 'HEADER_GROUP', 'FOCUS', 'LOCAL', 'GROUP'].forEach(name => {
		if(document.querySelector(TOGGLERS.SELECTOR[name])) toggleFns[name]();
	});
	
	/*
	if(document.querySelector(CONSTANTS.TOGGLERS.SELECTOR.GLOBAL)) Toggler.init(CONSTANTS.TOGGLERS.SELECTOR.GLOBAL);

	if(document.querySelector(CONSTANTS.TOGGLERS.SELECTOR.HEADER_GROUP)) initHeaderToggleGroup();

	if(document.querySelector(CONSTANTS.TOGGLERS.SELECTOR.FOCUS)) Toggler.init(CONSTANTS.TOGGLERS.SELECTOR.FOCUS, {
		callback(){
			if(this.isOpen) window.setTimeout(() => { document.getElementById('q').focus(); }, 16);
		}
	});
	if(document.querySelector(CONSTANTS.TOGGLERS.SELECTOR.LOCAL)) Toggler.init(CONSTANTS.TOGGLERS.SELECTOR.LOCAL, {targetLocal: true});

	if(document.querySelector(CONSTANTS.TOGGLERS.SELECTOR.GROUP)) initToggleGroup();
	*/
};